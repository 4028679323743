<template>
  <Modal :is-open="mergeWarning">
    <Stack tag="div" direction="col" gap="xl">
      <Stack tag="div" direction="col" gap="none">
        <Heading tag="h3" size="sm" class="text-charcoal-default mb-sm">Are you sure?</Heading>
        <Text tag="p" size="md">
          Changing your branch might affect the availability and price of products in your basket.
        </Text>
      </Stack>
      <Stack tag="div" slign="center" justify="end" gap="2xs" class="!w-full">
        <Button variant="neutral" size="md" @click="handleCancelMerge">No Thanks</Button>
        <Button variant="secondary" size="md" @click="handleAcceptMerge">Yes</Button>
      </Stack>
    </Stack>
  </Modal>
</template>

<script setup lang="ts">
const { selectBranch, selectedBranchId } = useBranches();

const { mergeWarning, mergeCancelBranch, setMergeWarning } = useUIState();

const handleCancelMerge = async () => {
  await selectBranch(Number(mergeCancelBranch.value));

  setMergeWarning(false, Number(mergeCancelBranch.value));
};

const handleAcceptMerge = async () => setMergeWarning(false, Number(selectedBranchId.value));
</script>
